var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title,"show-search":false}},[_c('template',{slot:"actions"},[(_vm.allowAction)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.command({ action: 'add' })}}},[_vm._v(" Add New Category ")]):_vm._e()],1)],2),(_vm.loading)?_c('eden-loader'):[(_vm.pageData.length)?_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatText(scope.row.category)))])]}}],null,false,1672706453)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Category ")])])],2),_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatDateAndTime(scope.row.created_at, "do m, y")))])]}}],null,false,2748720871)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Added on ")])])],2),_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatDateAndTime(scope.row.updated_at, "do m, y")))])]}}],null,false,353345458)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Updated on ")])])],2),(_vm.allowAction)?_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"eden-icon-edit-write","command":{
                  action: 'edit',
                  id: scope.row.id,
                  index: scope.$index,
                }}},[_vm._v("Edit category")]),_c('el-dropdown-item',{attrs:{"icon":"eden-icon-delete","command":{
                  action: 'delete',
                  id: scope.row.id,
                  index: scope.$index,
                }}},[_vm._v("Delete category")])],1)],1)]}}],null,false,1331840504)}):_vm._e()],1):_c('eden-content-empty',{attrs:{"text":'No categories'}})],_c('meal-category-form',{attrs:{"show":_vm.category.visibility,"action":_vm.category.action,"category":_vm.category.data},on:{"update:show":function($event){return _vm.$set(_vm.category, "visibility", $event)},"success":_vm.getCategories}}),_c('meal-category-delete',{attrs:{"show":_vm.showMealCategoryDelete,"category":_vm.category.data},on:{"update:show":function($event){_vm.showMealCategoryDelete=$event},"success":_vm.getCategories}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }