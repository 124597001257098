import axios from "axios";

export default {
  list() {
    return axios.get("meal_class_categories");
  },

  add(payload) {
    return axios.post("meal_class_categories", payload);
  },

  update(id, payload) {
    return axios.put(`meal_class_categories/${id}`, payload);
  },

  delete(id) {
    return axios.delete(`meal_class_categories/${id}`);
  },
};
