<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <el-button
          v-if="allowAction"
          type="primary"
          @click="command({ action: 'add' })"
        >
          Add New Category
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <el-table v-if="pageData.length" :data="pageData">
        <el-table-column min-width="300">
          <template slot="header">
            <span> Category </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatText(scope.row.category) }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="300">
          <template slot="header">
            <span> Added on </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatDateAndTime(scope.row.created_at, "do m, y") }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="300">
          <template slot="header">
            <span> Updated on </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatDateAndTime(scope.row.updated_at, "do m, y") }}</p>
          </template>
        </el-table-column>
        <el-table-column v-if="allowAction" width="60">
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-edit-write"
                  :command="{
                    action: 'edit',
                    id: scope.row.id,
                    index: scope.$index,
                  }"
                  >Edit category</el-dropdown-item
                >
                <el-dropdown-item
                  icon="eden-icon-delete"
                  :command="{
                    action: 'delete',
                    id: scope.row.id,
                    index: scope.$index,
                  }"
                  >Delete category</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No categories'" />
    </template>
    <meal-category-form
      :show.sync="category.visibility"
      :action="category.action"
      :category="category.data"
      @success="getCategories"
    />
    <meal-category-delete
      :show.sync="showMealCategoryDelete"
      :category="category.data"
      @success="getCategories"
    />
  </div>
</template>

<script>
import categories from "@/requests/services/meal/categories";
import MealCategoryForm from "@/components/Services/Meal/MealCategories/MealCategoryForm";
import MealCategoryDelete from "@/components/Services/Meal/MealCategories/MealCategoryDelete";

export default {
  name: "MealCategories",
  components: { MealCategoryDelete, MealCategoryForm },
  data() {
    return {
      loading: false,
      pageData: [],
      showMealCategoryDelete: false,
      category: {
        action: "add",
        visibility: false,
        data: {},
      },
    };
  },
  computed: {
    title() {
      return `${this.pageData.length} Categories`;
    },
    allowAction() {
      return this.allowAccessFor(["admin", "operations", "kitchen"]);
    },
  },
  created() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.loading = true;
      categories
        .list()
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.pageData = data.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    command(command) {
      switch (command.action) {
        case "add":
          this.category.data = {};
          this.category.action = "add";
          this.category.visibility = true;
          break;
        case "edit":
          this.category.data = this.pageData[command.index];
          this.category.action = "edit";
          this.category.visibility = true;
          break;
        case "delete":
          this.category.data = this.pageData[command.index];
          this.showMealCategoryDelete = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>
